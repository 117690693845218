import React from 'react'

import '../css/team.css'

import teamPhoto1 from "../assets/photo/team/IMG_4666.JPG"

function TeamItem(props) {
  return(
    <div className='teamItem'>
      <div className='teamItemImg'>
        <img src={props.source} alt="Failed to get :("></img>
      </div>
      <h2>{props.text}</h2>
      <h3>{props.rank}</h3>
    </div>
  );
}

function Team() {
  return(
    <div className='teamContainer'>
    <h1>Владелец</h1>
      <div className='teamWrap'>
        <TeamItem source={teamPhoto1} rank="Владелец. Дизайнер." text="Александр"></TeamItem>
      </div>
    </div>
  );
}

export default Team;