import React from 'react'

import '../css/about.css'

function  About() {
  return(
    <div className='aboutContainer' id='about'>
      <div className='aboutWrap'>
        <h1>О нас</h1>
        <p><span>Trap House</span> - Самая крупная огранизация вечеринок и концертов на Юге</p>
      </div>
    </div>
  );
}

export default About;